import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import('@/views/index.vue'),
    redirect: '/home',
    children : [
      {
        path: "/home",
        component: () => import('@/views/home/index.vue'),
        name: "home",
      },
      {
        path: "/talentMap",
        component: () => import('@/views/talentMap/index.vue'),
        name: "talentMap",
      },
      {
        path: "/talentGrow",
        component: () => import('@/views/talentGrow/index.vue'),
        name: "talentGrow",
      },
      {
        path: "/talentGrow/enroll",
        component: () => import('@/views/talentGrow/enroll/index.vue'),
        name: "enroll",
      },
      {
        path: "/talentPool",
        component: () => import('@/views/talentPool/index.vue'),
        name: "talentPool",
      },
      {
        path: "/talentShow",
        component: () => import('@/views/talentShow/index.vue'),
        name: "talentShow",
      },
      {
        path: "/talent/:id",
        component: () => import('@/views/talent/index.vue'),
        name: "talent",
      },
      {
        path: "/talent/:id/detail",
        component: () => import('@/views/talent/detail/index.vue'),
        name: "talent-detail",
      },
    ]
  },
  {
    path: "/*",
    component: () => import('@/views/notFound.vue'),
    name: "notFound",
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router;
