import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from '@jiaminghi/data-view';
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import * as echarts from 'echarts'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";

axios.defaults.baseURL = 'https://rc.dgjiakechong.com:9010/zscq/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前
  return config;
}, function (error) {
  // 请求错误
  return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 响应数据处理
  return response;
}, function (error) {
  // 响应错误处理
  if (error.response==null || error.response.status>=500){
    Element.Notification({
      title: '错误',
      message: '网络异常',
      type: 'error'
    })
  }
  return Promise.reject(error);
});


Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

Vue.component('icon', Icon);
Vue.use(dataV);
Vue.use(Element, { zIndex: 3000 });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
